import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Session } from "./Sessions";

function PrivateRoute({ component: Component, ...rest }) {
  let username = localStorage.getItem("username");
  const session = Session.get(username);
  console.log(session.timeLeft, session, username);
  if (session.timeLeft < 0) {
    Session.destroy();
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        session.active ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default PrivateRoute;
