import React from "react";
import { Route, Switch } from "react-router-dom";
import GuudReports from "../components/GuudReports";
import Login from "../components/Login";
import PrivateRoute from "../context/PrivateRoute";
import logout from "../context/LogOut";

function Router() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/logout" component={logout} />
      <PrivateRoute path="/guud-clients" component={GuudReports} />
    </Switch>
  );
}

export default Router;
