import React from "react";
import { Redirect } from "react-router-dom";
import { Session } from "./Sessions";

function logout() {
  let username = localStorage.getItem("username");
  //const session = Session.get(username);
  Session.destroy(username);
  localStorage.removeItem("username");
  return <Redirect to="/" />;
}

export default logout;
