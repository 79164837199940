import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = (props) => (
  <nav
    className="navbar navbar-expand-lg navbar-dark"
    style={{ background: "#242323" }}
  >
    <NavLink className="navbar-brand" to="/">
      Cash IN Asia Reports
    </NavLink>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav" style={{ flex: "1" }}>
        <NavLink exact className="navitem nav-link" to="/guud-clients">
          Guud-Clients
        </NavLink>
      </ul>

      <ul className="navbar-nav">
        <NavLink
          exact
          className="navitem nav-link"
          to="/logout"
          style={{ color: "#fff" }}
        >
          Log-out
        </NavLink>
      </ul>
    </div>
  </nav>
);

export default NavBar;
