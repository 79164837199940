import React, { Component } from "react";

import * as _ from "lodash";
import { Container } from "@material-ui/core";
import { Table } from "react-bootstrap";
import axios from "axios";

class GuudReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    this.getInfo();
  }
  getColor(total, current) {
    var per = 1 - current / total;
    console.log(per);
    if (per > 0.79) {
      return "#f8d7da";
    } else if (per > 0.5 && per < 0.79) {
      return "#ffeeba";
    } else {
      return "";
    }
  }

  render() {
    return (
      <Container style={{ marginTop: "30px" }}>
        <Table striped bordered hover>
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                Company Name
              </th>
              <th scope="col" className="border-0">
                Approved Date
              </th>
              <th scope="col" className="border-0">
                Refferal Code
              </th>
              <th scope="col" className="border-0">
                Total Credit limit
              </th>
              <th scope="col" className="border-0">
                Available Credit limit
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((data) => (
              <tr
                style={{
                  background: this.getColor(
                    data.amount,
                    data.current_credit_limit
                  ),
                }}
              >
                <td>{data.entity_name}</td>
                <td>{data.approved_date}</td>
                <td>{data.agent_code}</td>
                <td>{this.addCommas(data.amount)}</td>
                <td>{this.addCommas(data.current_credit_limit)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
  addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  getInfo = () => {
    axios
      .get(`https://sebackend.inft.co/get_guud_reports`)
      .then((res) => {
        // then print response status
        this.setState({ data: res.data });
      })
      .catch((err) => {
        alert(err);
      });
  };
}

export default GuudReports;
